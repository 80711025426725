<template>
	<div
		class="category-item"
		@click.stop="selectCurrentCategory(categoryInfo)"
		:title="categoryInfo.title"
		:class="{
			'subtree_opened': subCategoryTreeOpened
		}"
	>
		<span>{{categoryInfo.title}}</span>
		<keep-alive>
			<div
				v-if="categoryInfo.subtree && subCategoryTreeOpened"
				class="subtree"
			>
				<vod-categories-item-mobile
					v-for="category in categoryInfo.subtree"
					:key="category.id"
					:category-info=category
					@category-selected="selectCurrentCategory"
					:selectedCategory="selectedCategory"
					:class="{
                          selected: Number(category.id) === Number(selectedCategory)
                        }"
				/>
			</div>
		</keep-alive>
	</div>
</template>

<script>
  export default {
    name: "VodCategoriesItemMobile",
    data(){
      return {
        subCategoryTreeOpened: false
      }
    },
    props: {
      categoryInfo: {
        type: Object,
      },
      selectedCategory: {
        type: [Number, String]
      },
    },
    watch: {
      selectedCategory(newId){
        if (newId === this.categoryInfo.id && this.subCategoryTreeOpened) return;
        !this.subtreeHasSelectedCategory(this.categoryInfo.subtree, newId) && this.closeSubtree()
      }
    },
    methods: {
      closeSubtree(){
        this.subCategoryTreeOpened = false
      },
      toggleSubtree(){
        if (!this.categoryInfo.subtree) return
        this.subCategoryTreeOpened = !this.subCategoryTreeOpened
      },
      selectCurrentCategory(info) {
        this.$emit('category-selected', info);
        if (this.categoryInfo.id === info.id) {
          this.toggleSubtree();
        }
      },
      subtreeHasSelectedCategory(subtree, id) {
        if (!subtree) return false;
        return subtree.find((category) => {
          return Number(category.id) === Number(id) || category.subtree && this.subtreeHasSelectedCategory(category.subtree, id)
        })
      }
    },
    mounted() {
      this.subtreeHasSelectedCategory(this.categoryInfo.subtree, this.selectedCategory) &&
      this.toggleSubtree()
    }
  }
</script>

<style scoped lang="scss">
	@import "src/assets/css/colors";

	.category-item {
		display: flex;
		background: $secondary-background;
		line-height: 1.5;
		cursor: pointer;
		width: 100%;
		color: $link;
		flex-grow: 1;
		position: relative;
		flex-direction: column;

		span {
			padding: 1rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.selected {
		background: $accent-warning;
		font-weight: 600;
	}

	.subtree {
		display: flex;
		flex-direction: column;
	}

	.subtree_opened {
		background: rgba($accent-warning-rgb, .5);
		.category-item {
			border-left: 3px solid $accent-warning;
		}
	}
</style>